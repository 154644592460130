import React, { useEffect } from "react";
import {
    Create,
    required, FormDataConsumer, ReferenceInput, ArrayInput, SimpleFormIterator, NumberInput,
    TextInput, DateTimeInput, SelectInput, ImageInput, ImageField, RadioButtonGroupInput,
    CheckboxGroupInput, TranslatableInputs, BooleanInput,
    usePermissions, SelectArrayInput, ReferenceArrayInput, AutocompleteInput, AutocompleteArrayInput,
    TabbedForm, FormTab, DateInput, useDataProvider, Toolbar, SaveButton, Button, useCreateContext
} from 'react-admin';

// Components
import InputRow from '../../components/form/InputRow';
import SeatingPlanEditor from "../../components/seating/SeatingPlanEditor";

// Libs
import { useFormContext } from 'react-hook-form';
import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import moment from 'moment';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

// Utils
import { EditableImage } from "../../components/form/EditableImageField";

// UI
import { Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

// Constants
const ticketGroupStatuses = [{ id: 0, name: "On Sale" }, { id: 1, name: "Sold Out" }, { id: 2, name: "Hidden" }];
const dressCodes = [{ id: 1, name: "Casual" }, { id: 2, name: "Come as you are" }, { id: 3, name: "Elegant" }, { id: 4, name: "Smart Casual" }, { id: 5, name: "No Dress Code" }];
const couplesRules = [{ id: 1, name: "No Rules" }, { id: 2, name: "Men have to be with women" }];
const eventTypes = [{ id: 1, name: 'Standard Event' }, { id: 2, name: 'Area Layout Event' }, { id: 3, name: 'Seated Event' },];
const dateTypes = [{ id: 'oneoff', name: 'Single Date / One Off' }, { id: 'session', name: 'Multiple Dates / Sessions' }];

/**
 * 
 * @returns Event V2 Create
 */

export const EventV2Create = (props) => {
    const { permissions } = usePermissions();
    const [searchParams, setSearchParams] = useSearchParams();

    const [eventType, setEventType] = React.useState(null);
    const [dateType, setDateType] = React.useState(null);
    const [madeChocies, setMadeChoices] = React.useState(false);

    useEffect(() => {
        // see if this is a clone using searchParams    
        if (searchParams.get('source') && JSON.parse(searchParams.get('source')).type) {
            let source = JSON.parse(searchParams.get('source'));
            setEventType(source.type);
            setDateType(source.dateType);
            setMadeChoices(true);
        }
    }, []);

    if (!permissions) return;

    return (
        <Create title="Create New Event" {...props} redirect={"list"}>

            {
                madeChocies ? (
                    <EventV2CreateForm permissions={permissions} eventType={eventType} dateType={dateType} />
                ) : (
                    <div style={{ padding: 20, display: 'flex', flexDirection: 'column', alignContent: 'flex-start', alignItems: 'flex-start' }}>

                        <h1>Create New Event</h1>

                        <Box sx={{ minWidth: 200 }}>
                            <FormControl fullWidth>
                                <InputLabel id="event-type-select">Event Type</InputLabel>
                                <Select onChange={(e) => setEventType(e.target.value)}
                                    placeholder="TEST"
                                    defaultValue={""}
                                    defaultChecked={false}>
                                    {eventTypes.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ minWidth: 200 }}>
                            <FormControl fullWidth>
                                <InputLabel id="event-type-select">Event Date Type</InputLabel>
                                <Select
                                    defaultValue={""}
                                    disabled={!eventType} onChange={(e) => setDateType(e.target.value)}>
                                    {dateTypes.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Button label="Next" variant="contained" style={{ marginBottom: 10, marginTop: 20 }}
                            disabled={!eventType || !dateType}
                            onClick={() => {
                                if (!eventType) alert('Please select an event type first.');
                                else if (!dateType) alert('Please select a date type first.');

                                if (eventType && dateType) setMadeChoices(true);

                            }} />
                        Please select an event type and date type and click on the Next button to continue.
                    </div>
                )
            }

        </Create>
    );
}
const EventV2CreateForm = ({ permissions, eventType, dateType }, ...props) => {
    const dataProvider = useDataProvider();

    // fetch system parameters and get "system" doc to get the default values for the fees
    const [systemParams, setSystemParams] = React.useState(null);

    React.useEffect(() => {
        dataProvider.getOne('systemParameters', { id: 'system' })
            .then(res => {
                setSystemParams(res.data);
            });
    }, []);

    if (!systemParams) return "Loading...";

    return (
        <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', maxHeight: 40, marginBottom: 20, verticalAlign: 'center' }}>
                <h2>
                    New {eventTypes.find(item => item.id == eventType) && eventTypes.find(item => item.id == eventType).name} ({dateTypes.find(item => item.id == dateType) && dateTypes.find(item => item.id == dateType).name})
                </h2>

                <Button label="Reset" variant="outlined" style={{ marginTop: 20, marginLeft: 20, textAlign: 'center', paddingLeft: 10 }}
                    onClick={
                        () => {
                            if (window.confirm('Are you sure you want to reset the form?')) {
                                window.location.replace('/#/events-v2/create');
                                window.location.reload();
                            }
                        }
                    } />
            </div>

            <TabbedForm warnWhenUnsavedChanges id="eventForm" toolbar={<ActionToolbar eventType={eventType} dateType={dateType} />}>

                <FormTab label="Basic Details">

                    {/* <InsertDummyDataButton eventType={eventType} dateType={dateType} /> */}

                    <h3>Pre Selected Types</h3>
                    <EventPreSelectionInputs eventType={eventType} dateType={dateType} />

                    <h3>Basic Details</h3>

                    <NameInput />

                    <VenuePickerInput />

                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (!formData.venue) return null;
                            return <VenueCityInput venue={formData.venue} />
                        }}
                    </FormDataConsumer>

                    <ReferenceArrayInput source="organisationCompanies" reference="organisationCompanies" label={"Organisation Company"} defaultValue={[]} sort={{ field: 'name', order: 'ASC' }}>
                        <AutocompleteArrayInput
                            fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })}
                            optionText={"name"} disabled={permissions.crole && permissions.crole === "organisationCompanyManager"}
                            value={(permissions.crole && permissions.crole === "organisationCompanyManager") && [permissions.rid]}
                            defaultValue={(permissions.crole && permissions.crole === "organisationCompanyManager") ? [permissions.rid] : []}
                        />
                    </ReferenceArrayInput>

                    <h3>Category</h3>
                    <ReferenceInput source="category" reference="categories" sort={{ field: 'name', order: 'ASC' }}>
                        <SelectInput optionText={"name.en"} fullWidth validate={required()} />
                    </ReferenceInput>

                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            let filter = formData.category && formData.category.length > 0 ? { category: formData.category } : { category: 'dummy' };
                            return (
                                <ReferenceArrayInput source="subcategories" reference="subcategories" label={"Sub Categories"} filter={filter} sort={{ field: 'name.en', order: 'ASC' }}>

                                    {/* <SelectArrayInput optionText="name.en" fullWidth validate={required()} label={"Sub Categories"} /> */}

                                    <AutocompleteArrayInput
                                        fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ "name.en": searchText })} validate={required()}
                                        optionText={"name.en"}
                                        label={"Sub Categories"}
                                    />


                                </ReferenceArrayInput>
                            )
                        }}
                    </FormDataConsumer>

                    <h3>Payment Methods</h3>
                    {/* <h3>Payment Methods <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(*required)</span></h3> */}
                    <CheckboxGroupInput source="paymentMethods"
                        defaultValue={['cc']}
                        choices={[
                            { id: 'cc', name: 'Credit Card' },
                            // { id: 'doorcash', name: 'Cash At Door' }
                        ]} validate={required()} />

                </FormTab>

                <FormTab label="Date Details">

                    <FormDataConsumer>
                        {({ formData, getSource, ...rest }) => {

                            let dateType = formData.dateType;
                            let startDate = formData.startdate;
                            let duration = formData.duration;

                            let sessions = formData.sessions;

                            if (dateType === 'oneoff') {
                                return (
                                    <React.Fragment>
                                        <DateTimeInput
                                            source="startdate" label="Event Start Date" fullWidth
                                            // should not be before today
                                            validate={[
                                                required(),
                                                (value) => moment(value).isBefore(moment()) ? 'Start date should be after today.' : undefined
                                            ]}
                                        />
                                        <NumberInput source="duration" label="Event Duration (minutes)" fullWidth validate={required()} />
                                        {startDate && <EndDateInput startdate={startDate} duration={duration} />}
                                    </React.Fragment>
                                )
                            } else if (dateType === 'session') {
                                return (
                                    <React.Fragment>
                                        <NumberInput source="duration" label="Event Duration (minutes)" fullWidth validate={required()} />

                                        {/* Auto Start Date and End Date Computer  */}
                                        <SessionStartAndEndDateComputer sessions={sessions} />

                                        <ArrayInput source="sessions" defaultValue={[]} label="Sessions" validate={required()}>

                                            <SimpleFormIterator
                                                disableReordering
                                                // disableRemove
                                                className="sessionEventSessionsArray"
                                                addButton={<Button variant="contained" color="primary" style={{ marginTop: 20, marginBottom: 20 }} label="Add Date" />}>

                                                <DateInput source="startdate" fullWidth label="Date"
                                                    validate={[
                                                        required(),
                                                        (value) => moment(value).isBefore(moment()) ? 'Start date should be after today.' : undefined
                                                    ]}
                                                />

                                                <ArrayInput source="times" defaultValue={[]} label="Times">
                                                    <SimpleFormIterator
                                                        disableReordering
                                                        inline addButton={<Button variant="contained" color="primary"
                                                            style={{ marginTop: 20, marginBottom: 20 }} label="Add Time" />}>
                                                        <FormDataConsumer>
                                                            {({ formData, scopedFormData, getSource, ...rest }) => (
                                                                <React.Fragment>
                                                                    <TextInput source={getSource("id")} label={"Session ID"} validate={required()} fullWidth disabled defaultValue={genereateSessionID()} />
                                                                    <TextInput
                                                                        source={getSource('time')}
                                                                        fullWidth validate={required()}
                                                                        label="Time" type="time"
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                step: 300, // 5 min
                                                                            }
                                                                        }}
                                                                    />
                                                                    <BooleanInput source={getSource('isActive')} label="Active" defaultValue={true} defaultChecked={true} />
                                                                </React.Fragment>
                                                            )}
                                                        </FormDataConsumer>
                                                    </SimpleFormIterator>
                                                </ArrayInput>

                                            </SimpleFormIterator>
                                        </ArrayInput>
                                    </React.Fragment>
                                )
                            }

                        }}
                    </FormDataConsumer>


                </FormTab>

                <FormTab label="More Details">

                    <h3>Event Description <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(*required)</span></h3>
                    <RichTextInput source="description.tr" label="Description (Turkish)" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
                    <RichTextInput source="description.en" label="Description (English)" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />

                    <h3>Event Rules</h3>

                    <h4>Things to know <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(*required)</span></h4>
                    <RichTextInput source="details.tr" label="Event Rules (Turkish)" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
                    <RichTextInput source="details.en" label="Event Rules (English)" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />

                    <h4>Rule Chips</h4>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Rules that are left empty won't appear on the front-end website.</span>
                    <TextInput source="rules.age" label="Age Rule" fullWidth defaultValue={null} />
                    <InputRow>
                        <SelectInput source="rules.dress" choices={dressCodes} fullWidth defaultValue={[]} />
                        <SelectInput source="rules.couples" choices={couplesRules} fullWidth defaultValue={[]} />
                    </InputRow>
                </FormTab>

                <FormTab label="Visuals">
                    <h3>Visual / Poster</h3>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Square ratio images that are of size 500 x 500 should be uploaded.</span>
                    <EditableImage source="banner" {...props} />
                    <h3>Event Video</h3>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Please note that the entry should only contain the ID (the code after ?v=) of the video NOT the whole URL.</span>
                    <TextInput source="videoURL" label={"Video ID (Youtube ID)"} fullWidth defaultValue={null} />

                    <h3>Venue Layout</h3>
                    <span style={{ fontSize: 14, marginTop: -10, marginBottom: 20 }}>
                        If there is a specific layout for this event. If not default layout for the venue will be used.
                    </span>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Square ratio images should be uploaded. Both sides should be equal.</span>
                    <ImageInput source="venueLayout" label=" " accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>
                </FormTab>

                <FormTab label="Products/Tickets">

                    {/* FormDataConsumer */}
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            return (formData.type == 1 || formData.type == 2) ? <StandardProductFormComponent /> : <SeatedProductFormComponent />
                        }}
                    </FormDataConsumer>

                </FormTab>

                {
                    (eventType == 2 || eventType == 3) && (
                        <FormTab label="Layout">
                            <h3>Layout</h3>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {

                                    if (!formData.venue) {
                                        return (
                                            <span style={{ fontSize: 14, marginTop: -10, marginBottom: 20 }}>
                                                Venue should be selected on <b>Basic Details</b> section to see the available layouts.
                                            </span>
                                        )
                                    }

                                    let layoutType = eventType == 2 ? 'area' : 'seating';
                                    let filter = { venue: formData.venue, layoutType: layoutType };

                                    if (formData.layoutID) {
                                        return (
                                            <React.Fragment>
                                                <ReferenceInput source="layoutID" label="Layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }}
                                                    validate={required()}
                                                >
                                                    <SelectInput validate={required()} optionText={"name"} fullWidth label="Layout" emptyText={`
                                                        Please select ${eventType == 2 ? 'an AREA' : 'a SEATING'} layout for the venue chosen. If there is no layout available, please create one using the Layouts module.
                                                    `} />
                                                </ReferenceInput>
                                                <SeatingPlanEditor record={formData.layoutID} isAssignMode={true} tickets={formData.tickets} dataProvider={dataProvider} isStandardEvent={true} layoutType={layoutType} />
                                            </React.Fragment>
                                        );
                                    } else {
                                        return (
                                            <ReferenceInput source="layoutID" label="Layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }}
                                                validate={required()}
                                            >
                                                <SelectInput validate={required()} optionText={"name"} fullWidth label="Layout" emptyText={`
                                                    Please select ${eventType == 2 ? 'an AREA' : 'a SEATING'} layout for the venue chosen. If there is no layout available, please create one using the Layouts module.
                                                `} />
                                            </ReferenceInput>
                                        );
                                    }

                                }}
                            </FormDataConsumer>

                        </FormTab>
                    )
                }

                <FormTab label="Coupons">
                    <h3>Coupons</h3>
                    <ArrayInput source="coupons" defaultValue={[]} label=" ">
                        <SimpleFormIterator disableReordering className="couponsIterator">

                            {/* Disable changing of the discount amount and code if used > 0 */}
                            <FormDataConsumer>
                                {({ formData, scopedFormData, getSource, ...rest }) => {
                                    return (
                                        <React.Fragment>
                                            <TextInput source={getSource("code")} label={"Coupon Code"} validate={required()} fullWidth
                                                helperText={scopedFormData.used && scopedFormData.used > 0 ? 'If you need to change the coupon code for a USED coupon, you can do so by creating a new one' : ''}
                                                disabled={scopedFormData.used && scopedFormData.used > 0}
                                            />

                                            <NumberInput
                                                onWheel={event => { event.target.blur(); }} source={getSource("discount")} type="number" label={"Discount (TL)"} min={0}
                                                validate={required()} fullWidth disabled={scopedFormData.used && scopedFormData.used > 0}
                                                helperText={scopedFormData.used && scopedFormData.used > 0 ? 'If you need to change the discount amount for a USED coupon, you can do so by creating a new one' : ''}
                                            />

                                            <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("stock")} type="number" label={"Stock"} validate={required()} fullWidth />
                                            {/* <NumberInput onWheel={event => { event.target.blur(); }} source="used" type="number" label={"Used"} fullWidth disabled /> */}

                                            <DateInput source={getSource("activeUntil")} label={"Active Until"} validate={[
                                                required(),
                                                (value) => moment(value).isBefore(moment()) ? 'Active until date should be after today.' : undefined,
                                                (value) => moment(value).isAfter(moment(formData.startdate)) ? 'Active until date should be before the event start date.' : undefined
                                            ]} fullWidth />

                                        </React.Fragment>
                                    );
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>

                <FormTab label="Fees">
                    {
                        systemParams && systemParams.variables ? (
                            <React.Fragment>
                                <NumberInput onWheel={event => { event.target.blur(); }} source="servicefee" min={0} fullWidth label="Event Service Fee (TL)"
                                    helperText="Service fee to cover extra operational cost"
                                    defaultValue={systemParams.variables.defaultServiceFee}
                                    disabled={(permissions.crole && permissions.crole !== "admin")}
                                />

                                <NumberInput onWheel={event => { event.target.blur(); }} source="commissionFee"
                                    min={0} fullWidth label="Event Commission Fee (%)"
                                    defaultValue={systemParams.variables.defaultCommissionFee}
                                    disabled={(permissions.crole && permissions.crole !== "admin")}
                                />

                                <BooleanInput source="isCommissionExtra" defaultValue={false} label={`Is commission extra (added to ticket prices)?`} helperText="Please note that the prices for the event will change if this is turned on." />
                            </React.Fragment>
                        ) : (
                            <div style={{ padding: 20, color: 'red' }}>
                                <h3>System Parameters could not be loaded, this is an error that needs to be reported to Gişe Kıbrıs. Please do so before creating your event.</h3>
                            </div>
                            // <React.Fragment>
                            //     <NumberInput onWheel={event => { event.target.blur(); }} source="servicefee" min={0} fullWidth label="Event Service Fee (TL)" helperText="Service fee to cover extra operational cost" defaultValue={0} />
                            //     <NumberInput onWheel={event => { event.target.blur(); }} source="commissionFee" min={0} fullWidth label="Event Commission Fee (%)" defaultValue={10} />
                            //     <BooleanInput source="isCommissionExtra" defaultValue={false} label="Is commission extra (added to ticket prices)?" helperText="Please note that the prices for the event will change if this is turned on." />
                            // </React.Fragment>
                        )
                    }
                </FormTab>

                {/* FormTab - Contract */}

                <FormTab label="Settings">
                    <SettingsInputs permissions={permissions} />
                    {/* <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            return <TicketsInputFixer eventType={eventType} tickets={formData.tickets} />
                        }}
                    </FormDataConsumer> */}
                </FormTab>
            </TabbedForm>
        </React.Fragment>
    )
}

// FORM COMPONENTS
const ActionToolbar = ({ eventType, dateType }) => (
    <Toolbar>
        <SaveButton
            label="Save"
            alwaysEnable
            transform={data => {

                let toReturn = { ...data };

                // Tickets
                if (eventType == 1 || eventType == 2) {
                    let tickets = data.tickets;
                    let newTickets = {
                        deal: tickets.deal,
                        guestlist: tickets.guestlist,
                        general: tickets.general,
                        vip: tickets.vip,
                        extras: tickets.extras,
                        invitation: tickets.invitation
                    };

                    toReturn.tickets = newTickets;
                }

                // Dates
                if (dateType == 'oneoff') {
                    let sessions = [
                        {
                            startdate: moment(data.startdate).format('YYYY-MM-DD'),
                            times: [
                                {
                                    isActive: true,
                                    id: genereateSessionID(),
                                    time: moment(data.startdate).format('HH:mm'),
                                }
                            ]
                        }
                    ];

                    toReturn.sessions = sessions;
                }

                return { ...toReturn, notify: true };

            }}
            type="button"
        />
    </Toolbar>
);
const EndDateInput = ({ startdate, duration }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        const startDate = new Date(startdate);
        const endDate = new Date(startDate.getTime() + duration * 60000);
        setValue('enddate', endDate);
    }, [startdate, duration]);


    return (
        <DateTimeInput source="enddate" label="Event End Date" fullWidth validate={required()} disabled />
    )

}
const EventPreSelectionInputs = ({ eventType, dateType }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        setValue('type', eventType);
        setValue('dateType', dateType);

    }, [eventType, dateType]);

    return (
        <React.Fragment>
            <SelectInput source="type" label="Event Type" fullWidth disabled choices={eventTypes} />
            <SelectInput source="dateType" label="Event Date Type" fullWidth disabled choices={dateTypes} />
        </React.Fragment>
    )

}
const StandardProductFormComponent = ({ }) => {
    return (
        <React.Fragment>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: 20 }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <div style={{ border: '1px dashed #000', padding: 5 }}>
                        <h3>Special Deal <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                        <ArrayInput source="tickets.deal" defaultValue={[]}>
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return standardProductForm(getSource, true, scopedFormData, false);
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <div style={{ border: '1px dashed #000', padding: 5 }}>
                        <h3>Reservation <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                        <ArrayInput source="tickets.guestlist" defaultValue={[]}>
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return standardProductForm(getSource, true, scopedFormData, false);
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>
                </Box>
            </Box>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: 20 }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <div style={{ border: '1px dashed #000', padding: 5 }}>
                        <h3>General <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                        <ArrayInput source="tickets.general" defaultValue={[]}>
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return standardProductForm(getSource, true, scopedFormData, false);
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>

                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <div style={{ border: '1px dashed #000', padding: 5 }}>
                        <h3>VIP List <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                        <ArrayInput source="tickets.vip" defaultValue={[]}>
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return standardProductForm(getSource, true, scopedFormData, false);
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>
                </Box>
            </Box>


            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <div style={{ border: '1px dashed #000', padding: 5 }}>
                        <h3>Extras <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                        <ArrayInput source="tickets.extras" defaultValue={[]}>
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return standardProductForm(getSource, true, scopedFormData);
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <div style={{ border: '1px dashed #000', padding: 5 }}>
                        <h3>Invitations <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                        <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Can create invitations for guests to attend the event.</span>
                        <ArrayInput source="tickets.invitation" defaultValue={[]}>
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return standardProductForm(getSource, true, scopedFormData);
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>
                </Box>
            </Box>
        </React.Fragment>
    );
}
const SeatedProductFormComponent = ({ }) => {
    return (
        <ArrayInput source="tickets" defaultValue={[]}>
            <SimpleFormIterator disableReordering className="sessionEventTicketsArray">
                <FormDataConsumer>
                    {({ formData, scopedFormData, getSource, ...rest }) => {
                        return seatedProductForm(getSource, true, scopedFormData);
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    )
}
const VenueCityInput = ({ venue }) => {
    const { setValue } = useFormContext();
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider.getOne('venues', { id: venue })
            .then(res => {
                setValue('city', res.data.city);
            });

    }, [venue]);

    return (
        <TextInput source="city" label="City" fullWidth disabled />
    )

}
const VenuePickerInput = ({ }) => {
    const { setValue } = useFormContext();
    return (
        <ReferenceInput source="venue" reference="venues" sort={{ field: 'name', order: 'ASC' }} validate={required()}>
            <AutocompleteInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} validate={required()}
                onChange={() => {
                    setValue('layoutID', null);
                }}
            />
        </ReferenceInput>
    )
}
const SettingsInputs = ({ permissions }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        setValue('isActive', (permissions.crole && permissions.crole === "admin") ? true : false);
        setValue('isVerified', (permissions.crole && permissions.crole === "admin") ? true : false);
    }, []);

    let isDisabled = (permissions.crole && permissions.crole === "admin") ? false : true;

    return (
        <React.Fragment>
            <BooleanInput source="isActive" label="Show this event on the website?" helperText="Please note that the event should be 'saved' for this change to take effect." disabled={isDisabled} />
            <BooleanInput source="isVerified" label="Verified Event?" helperText="Is event verified by the admin?" disabled={isDisabled} />
        </React.Fragment>

    )
}
const NameInput = ({ }) => {
    const { setValue } = useFormContext();

    // as the user types, the name should be auto capitalized
    const handleNameChange = (e) => {
        setValue('name', e.target.value.toUpperCase());
    }

    return (
        <TextInput source="name" label="Event Name" fullWidth validate={required()} onBlur={handleNameChange} />
    )
}
const SessionStartAndEndDateComputer = ({ sessions }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        if (sessions && sessions.length > 0) {
            let startdate = moment(sessions[0].startdate).set({ hour: 0, minute: 0, second: 0 }).toDate();
            let enddate = moment(sessions[sessions.length - 1].startdate).set({ hour: 23, minute: 59, second: 59 }).toDate();
            setValue('startdate', startdate);
            setValue('enddate', enddate);
        }
    }, [sessions]);

    return null;
    // return (
    //     <React.Fragment>
    //         <DateTimeInput source="startdate" label="Event Start Date" fullWidth validate={required()} disabled />
    //         <DateTimeInput source="enddate" label="Event End Date" fullWidth validate={required()} disabled />
    //     </React.Fragment>
    // )

}

// PRODUCT FORMS
const seatedProductForm = (getSource, showSold, scopedFormData) => {
    return (
        <React.Fragment>
            {/* Auto generate ticket ID on add */}
            <TextInput source={getSource("id")} label={"Ticket ID"} validate={required()} fullWidth disabled defaultValue={genereateTicketID()} />

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TranslatableInputs locales={['tr', 'en']} className="translatableInputST" defaultLocale="tr">
                        <TextInput source={getSource("title")} label={"Title"} validate={required()} fullWidth autoComplete="off" />
                    </TranslatableInputs>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TranslatableInputs locales={['tr', 'en']} defaultLocale="tr">
                        <TextInput source={getSource("description")} label={"Description"} validate={required()} fullWidth multiline rows={3} />
                    </TranslatableInputs>
                </Box>
            </Box>
            <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("price")} type="number" label={"Price"} validate={required()} fullWidth />
            <BooleanInput source={getSource('isPromotionAvailable')} label={"Promotion Available?"} fullWidth />


            {scopedFormData && scopedFormData.isPromotionAvailable && (
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TranslatableInputs locales={['tr', 'en']} defaultLocale="tr">
                            <TextInput source={getSource("promotion.label")} label={"Promotion Label"} fullWidth />
                        </TranslatableInputs>
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("promotion.price")} type="number" label={"Promotion Price"} fullWidth />
                    </Box>
                </Box>
            )}

            {showSold && <NumberInput source={getSource("sold")} type="number" value={0} defaultValue={0} label={"Total Sold"} disabled fullWidth />}
        </React.Fragment>
    )
}
const standardProductForm = (getSource, showSold, scopedFormData, isEditForm) => {
    return (
        <React.Fragment>

            {/* Auto generate ticket ID on add */}
            <TextInput source={getSource("id")} label={"Ticket ID"} validate={required()} fullWidth disabled defaultValue={genereateTicketID()} />

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source={getSource("title.tr")} label={"Turkish Title"} validate={required()} fullWidth className="autoCapitalize" autoComplete="off" />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source={getSource("title.en")} label={"English Title"} validate={required()} fullWidth className="autoCapitalize" autoComplete="off" />
                </Box>
            </Box>
            <Box>
                {/* <TextInput source={getSource("description.tr")} label={"Turkish Description"} validate={required()} fullWidth multiline rows={3} /> */}
                <RichTextInput source={getSource("description.tr")} label="Turkish Description" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
                {/* <TextInput source={getSource("description.en")} label={"English Description"} validate={required()} fullWidth multiline rows={3} /> */}
                <RichTextInput source={getSource("description.en")} label="English Description" validate={required()} fullWidth multiline rows={3} toolbar={<RichTextInputToolbar size="small" />} />
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <NumberInput
                        onWheel={event => { event.target.blur(); }} source={getSource("price")} type="number" label={"Price"} min={0}
                        validate={required()} fullWidth disabled={isEditForm && scopedFormData.sold && scopedFormData.sold > 0}
                        helperText={isEditForm ? 'If you need to change the price of a product, you can do so by creating a new product and hiding the old one.' : ''}
                    />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("stock")} type="number" label={"Stock"} validate={required()} fullWidth />
                </Box>
            </Box>
            {showSold && <NumberInput source={getSource("sold")} type="number" value={0} defaultValue={0} label={"Sold"} disabled fullWidth />}

            <BooleanInput source={getSource("hasDownPayment")} label={"Has Down Payment?"} defaultValue={false} />

            {
                scopedFormData.hasDownPayment && (
                    <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("downPaymentAmount")} type="number" label={"Down Payment Price"} validate={required()} min={0} fullWidth />
                )
            }

            <BooleanInput source={getSource("hasBasketLimit")} label={"Has Basket Limit?"} defaultValue={false} />

            {
                scopedFormData.hasBasketLimit && (
                    <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("basketLimit")} type="number" label={"Basket Limit"} validate={required()} fullWidth />
                )
            }

            <RadioButtonGroupInput source={getSource("status")} label={"Status"} choices={ticketGroupStatuses} defaultValue={0} validate={required()} />
        </React.Fragment>
    )
}

// UTILS
const genereateTicketID = () => {
    // Capital letters and numbers, length 8
    return Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substring(0, 8).toUpperCase();
}
const genereateSessionID = () => {
    // Capital letters and numbers, length 12
    return Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substring(0, 12).toUpperCase();
}