import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// React-admin
import { usePermissions, useGetOne } from "react-admin";

// Services
import { db } from "../database/firebase";
import { collection, getDocs, query, orderBy, limit, where } from "firebase/firestore";

// Libs
import { DataGridPremium, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-premium';

// Icons
import { Refresh } from '@mui/icons-material';

import moment from 'moment';
import EmptyList from "../components/misc/EmptyList";

const D_LAST_X_ROWS = 100;
const D_ORDER_ON = 'created';
const D_ODER = 'desc';
const D_ENTITY = 'tickets';
const D_ENTITY_LABEL = 'Tickets';
const D_COLUMNS = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'pnr', headerName: 'PNR', width: 120 },
    {
        field: 'print', headerName: 'Print Ticket', width: 150, renderCell: (params) => {
            let r = params.row;
            return (
                <>
                    <a href={`https://gisekibris.com/etkinlik-bileti/${r.pnr}`} target="_blank" rel="noreferrer">Ticket</a> &nbsp;| &nbsp;
                    <a href={`https://gisekibris.com/bilet-yazdir/${r.pnr}`} target="_blank" rel="noreferrer"> 8cm</a>
                </>
            )
        }
    },
    {
        field: 'channel', headerName: 'Sale Channel', width: 80, valueGetter: (params) => {
            switch (params.row.channel) {
                case "w":
                    return "Website";
                case "m":
                    return "Mobile";
                case "sp":
                    return "Sale Point"
                case "sa":
                    return "Sale Agent";
                case "op":
                    return "Org C. Promoter"
                case "vp":
                    return "Venue Promoter";
                case "a":
                    return "Affiliate";
                case "ocm":
                    return "Org C. Manager";
                case "vm":
                    return "Venue Manager";
                default:
                    return "N/A"
            }
        }
    },
    {
        field: 'userName', headerName: 'User', width: 120, valueGetter: (params) => {
            let r = params.row;
            if (r.user && r.user.name) return r.user.name.concat(' ', r.user.surname);
            else return "N/A";
        }
    },
    { field: 'eventName', headerName: 'Event Name', width: 250, valueGetter: (params) => params.row.event.name },
    {
        field: 'created', headerName: 'Sale Date', width: 150, valueGetter: (params) => {
            let r = params.row;
            if (r.created) return moment(r.created, 'x').format('DD/MM/YY LT');
            else return "N/A";
        }
    },
    {
        field: 'product', headerName: 'Product', width: 180, valueGetter: (params) => {
            let r = params.row;
            if (r.seats && r.seats.length > 0) {
                let seats = r.seats.map((seat) => seat.id);
                return seats.join(', ');
            }
            else if (r.product && r.product.title && r.product.title.en) {
                return r.product.title.en;
            }
            else return "N/A";
        }
    },
    {
        // product.key
        field: 'productKey', headerName: 'Product Key', width: 150, valueGetter: (params) => {
            let r = params.row;

            if (r.isInvitation) return "Invitation";
            if (r.isReservation) return "Reservation";

            if (r.product && r.product.key) return r.product.key;
            else if (r.seats) {
                return r.seats.map((seat) => seat.selectedOption.title.en).join(', ');
            }
            else return "N/A";
        }
    },
    {
        field: 'isUsed', headerName: 'Is Used?', width: 100, valueGetter: (params) => {
            let r = params.row;
            if (r.isUsed) return "Yes";
            else return "No";
        },
        renderCell: (params) => {
            let r = params.row;
            if (r.isUsed) {
                return (
                    <div style={{ backgroundColor: "green", textAlign: 'center', borderRadius: 5, padding: 3 }}>
                        <span style={{ color: 'white', padding: 2 }}><DynamicCell id={r.isUsedBy} reference="users" renderFunction={(r) => `${r.name} ${r.surname.toString()[0]}.`} /></span>
                    </div>
                )
            }
            else return (
                <div style={{ backgroundColor: "red", textAlign: 'center', borderRadius: 5, padding: 3 }}>
                    <span style={{ color: 'white', textAlign: 'center' }}>{"NO"}</span>
                </div>
            )

        }
    }
]

const INITIALLY_VISIBLE_COLUMNS = {
    id: false, coupon: false, payment: true, channel: false
};

const LastXROWS = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams()
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState(D_COLUMNS);
    const [showSeatOverlapChecker, setShowSeatOverlapChecker] = useState(false);
    const { permissions } = usePermissions();

    // Get eventID from URL
    const eventID = searchParams.get('eventID');
    const stockID = searchParams.get('stockID');

    let isManager = false;
    if (permissions.crole && permissions.crole === "organisationCompanyManager" && permissions.rid) isManager = true;
    if (permissions.crole && permissions.crole === "venueManager" && permissions.rid) isManager = true;

    const getData = async () => {

        const collectionRef = collection(db, D_ENTITY);

        // TODO: add filter by organisation or venue
        let q = null;
        if (eventID && !stockID) q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), where('event.id', '==', eventID));
        else if (stockID) q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), where('event.stockID', '==', stockID));
        else q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), limit(D_LAST_X_ROWS));

        const dataSnapshot = await getDocs(q);

        let isShowingSeatOverlapChecker = false;

        if (dataSnapshot) {

            let data = [];
            dataSnapshot.forEach((doc) => {

                let ticketData = doc.data();

                if (ticketData && ticketData.seats && ticketData.seats.length > 0) isShowingSeatOverlapChecker = true;

                data.push(
                    {
                        id: doc.id,
                        ...doc.data()
                    }
                );
            });

            if (isManager) {
                // remove channel from columns if the user is a manager
                let newColumns = columns.filter((column) => column.field !== 'channel');
                setColumns(newColumns);
            }

            setData(data);
            if (isShowingSeatOverlapChecker) setShowSeatOverlapChecker(true);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (isManager && !eventID) return window.location.href = '/#/events-v2';
        else getData();
    }, [searchParams]);


    if (!permissions) return <EmptyList mode="loading" />
    if (isLoading) return <EmptyList mode="loading" />


    const CustomTableHeader = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport
                    csvOptions={{ disableToolbarButton: true }}
                    excelOptions={{
                        fields: [
                            'pnr', 'channel', 'userName', 'eventName', 'created', 'product', 'productKey'
                        ],
                        // file name in the format of "OrganisationName - EventName - Tickets - DD/MM/YYYY"
                        fileName: `exported-tickets-${moment().format('DD/MM/YYYY')}`
                    }}
                    printOptions={{
                        hideToolbar: true,
                        fields: [
                            'pnr', 'channel', 'userName', 'eventName', 'created', 'product'
                        ]
                    }}
                />
                {/* <RefreshButton refreshData={getData} /> */}
            </GridToolbarContainer>
        );
    }


    return (
        <div>
            {
                eventID ? (
                    eventID && stockID ? (
                        <div style={{ marginLeft: 20 }}>
                            <h1>Event Session / Tickets List</h1>
                            <span>This list shows all tickets for the selected event session.</span>
                        </div>
                    )
                        : (
                            <div style={{ marginLeft: 20 }}>
                                <h1>Event / Tickets List</h1>
                                <span>This list shows all tickets for the selected event.</span>
                            </div>
                        )
                )
                    : (
                        <div style={{ marginLeft: 20 }}>
                            <h1>{D_ENTITY_LABEL} List</h1>
                            <span>This list shows the last {D_LAST_X_ROWS} entries ordered on date/time, in order to view more and specific {D_ENTITY} you can query them via events page.</span>
                        </div>
                    )
            }

            {
                showSeatOverlapChecker && data && (
                    <SeatOverlapChecker tickets={data} />
                )
            }

            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', margin: 0, padding: 0, width: '100%' }}>
                <div className="container" style={{ padding: 20, width: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <DataGridPremium
                                rows={data}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: INITIALLY_VISIBLE_COLUMNS
                                    }
                                }}
                                density="compact"
                                getRowId={(row) => row.id}
                                columns={columns}
                                pageSize={10}
                                // hideFooter
                                slots={{
                                    toolbar: CustomTableHeader
                                }}

                                style={{ flex: 1, width: '100%', minHeight: '600px' }}
                                loading={isLoading}
                                slotProps={{
                                    loadingOverlay: {
                                        variant: 'linear-progress',
                                        noRowsVariant: 'skeleton',
                                    }
                                }}
                                localeText={{ noRowsLabel: "No tickets found matching the criteria." }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

const RefreshButton = ({ refreshData }) => {
    return (
        <button
            style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: 'black',
                cursor: 'pointer',
                fontSize: 10,
                fontWeight: 500,
                outline: 'none',
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center'
            }}
            onClick={refreshData}
        >
            {/* refresh icon */}
            <Refresh style={{ fontSize: 16, marginRight: 5 }} />
            REFRESH
        </button>
    );
};

const DynamicCell = ({ reference, id, field, suffix, renderFunction }) => {
    const { data, isLoading, error } = useGetOne(
        reference,
        { id }
    );

    if (isLoading) return <span>Loading...</span>
    if (error) return <span>Error</span>

    if (!field) {
        if (renderFunction) return <span>{renderFunction(data)}</span>
        else return <span>{data}</span>
    }

    if (field.indexOf('+') > -1) {
        let fields = field.split('+');
        return <span>{data[fields[0]]} {data[fields[1]]}</span>
    }
    else {
        if (renderFunction) {
            // console.log('renderFunction', renderFunction)
            // console.log('data[field]', data[field])
            return <span>{renderFunction(data[field])}</span>
        }
        else return <span>{data[field]}{suffix}</span>
    }
}

const SeatOverlapChecker = ({ tickets }) => {

    let seats = [];
    let overlapSeats = [];

    const checkSeatOverlap = () => {
        let overlap = false;
        tickets.forEach((ticket) => {
            if (ticket.seats) {
                ticket.seats.forEach((seat) => {
                    if (seats.indexOf(seat.id) > -1) {
                        overlap = true;
                        overlapSeats.push(seat.id);
                    } else seats.push(seat.id);
                })
            }
        });

        return overlap ? seats : false;
    }

    return checkSeatOverlap() && (
        <div style={{ backgroundColor: 'red', color: 'white', padding: 20, marginTop: 10 }}>
            <span>Error! There is overlap in {overlapSeats.length} seats: {overlapSeats.join(', ')}</span>
        </div>
    )
}

export default LastXROWS;